import { HTMLMotionProps, motion } from 'framer-motion';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & HTMLMotionProps<'div'>;

export function CopyPopupAnimation({ children, ...rest }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.85 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.85 }}
      transition={{ duration: 0.15 }}
      {...rest}>
      {children}
    </motion.div>
  );
}
