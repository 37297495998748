import { mergeClasses } from '@expo/styleguide';
import { ClipboardIcon } from '@expo/styleguide-icons/outline/ClipboardIcon';
import { AnimatePresence } from 'framer-motion';
import { PropsWithChildren } from 'react';

import { useCopy } from '~/providers/useCopy';
import { Button } from '~/ui/components/Button';
import { CALLOUT, FOOTNOTE } from '~/ui/components/text';

import { CopyPopupAnimation } from './CopyPopupAnimation';

type WithCopyProps = PropsWithChildren<{
  valueToCopy?: string | null;
  className?: string;
  valueToCopyAsync?: () => Promise<string>;
}>;

export function WithCopy({
  children,
  className,
  valueToCopy: copiedValue,
  valueToCopyAsync,
}: WithCopyProps) {
  const isStringValue = typeof children === 'string';
  const { copiedIsVisible, onCopyAsync, onCopyDynamicAsync } = useCopy(
    copiedValue ?? (isStringValue ? children : '')
  );

  const onClickAsync = async () => {
    if (valueToCopyAsync) {
      const text = await valueToCopyAsync();
      await onCopyDynamicAsync(text);
    } else {
      await onCopyAsync();
    }
  };

  return (
    <>
      {isStringValue ? (
        <CALLOUT className={mergeClasses('whitespace-nowrap', className)}>{children}</CALLOUT>
      ) : (
        children
      )}
      <div className="relative">
        <Button
          leftSlot={<ClipboardIcon className="icon-xs" />}
          theme="secondary"
          size="xs"
          className="h-7"
          onClick={onClickAsync}
        />
        <AnimatePresence>
          {copiedIsVisible && (
            <CopyPopupAnimation className="absolute right-[48px] top-[calc(50%-14px)]">
              <FOOTNOTE
                theme="success"
                className="absolute -right-2 flex h-[28px] items-center rounded-md border border-success bg-success px-2">
                Copied!
              </FOOTNOTE>
            </CopyPopupAnimation>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
